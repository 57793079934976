<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadLandContracts(true)" />
                </div>
            </div>
            <el-table :data="landContracts.data" class="mt-2">
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/land-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="partnerName" label="土地来源方" sortable />
                <el-table-column prop="contractArea" label="合同面积(亩)" sortable width="140" />
                <el-table-column prop="contractAmount" label="合同金额(元)" sortable width="140" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="240">
                    <template #default="scope">
                        <el-button type="text" @click="showLandContractOnlinePageDialog(scope.row)">查看合同</el-button>
                        <el-button type="text" @click="syncLandContractOnline(scope.row.id)">同步状态</el-button>
                        <el-button type="text" @click="showSignLandContractOfflineDialog(scope.row)">转线下签约</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="landContracts.totalRecords"
                :current-page="landContractsPagination.currentPage" :page-size="landContractsPagination.pageSize"
                class="mt-3" @current-change="landContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="landContractOnlinePageDialogVisible" title="合同" :close-on-click-modal="false"
            width="960px" top="0px">
            <iframe v-if="landContractOnlinePageDialogVisible&&contractPageUrl" :src="contractPageUrl" frameborder="0"
                class="d-block border border-primary rounded w-100" style="height: calc(100vh - 110px);"></iframe>
        </el-dialog>

        <el-dialog :visible.sync="signLandContractOfflineDialogVisible" title="线上转线下签约" :close-on-click-modal="false"
            width="640px">
            <el-form>
                <el-form-item label="线上转线下原因" class="form-item-required">
                    <el-input v-model="reason" placeholder="线上转线下原因" />
                </el-form-item>
            </el-form>
            <div>
                <div>线下签约合同附件</div>
                <div class="border rounded mt-2 p-1">
                    <multi-file-uploader v-model="landContract.signOfflineContractAttachments"
                        accept=".pdf,.jpg,.jpeg,.png" />
                </div>
                <div class="text-info mt-2">上传已线下签章生效的PDF、图片附件。</div>
            </div>
            <template #footer>
                <el-button type="primary" @click="signLandContractOffline">确定</el-button>
                <el-button @click="signLandContractOfflineDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                landContracts: {},
                landContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                landContract: {},
                contractPageUrl: null,
                reason: null,
                landContractOnlinePageDialogVisible: false,
                signLandContractOfflineDialogVisible: false,
            };
        },
        methods: {
            async loadLandContracts(resetPage = false) {
                if (resetPage) {
                    this.landContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/LandContract/GetLandContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 4,
                        key: this.key,
                        pageIndex: this.landContractsPagination.currentPage - 1,
                        pageSize: this.landContractsPagination.pageSize,
                    }
                });
                this.landContracts = response.data;
            },
            landContractsPaginationCurrentChange(page) {
                this.landContractsPagination.currentPage = page;
                this.loadLandContracts();
            },
            showLandContractOnlinePageDialog(landContract) {
                this.landContract = JSON.parse(JSON.stringify(landContract));
                this.contractPageUrl = null;
                this.landContractOnlinePageDialogVisible = true;

                this.loadContractPageUrl();
            },
            async loadContractPageUrl() {
                let response = await this.$axios.get('/api/Contract/GetContractPageUrl', {
                    params: { contractId: this.landContract.signOnlineContractId }
                });
                this.contractPageUrl = response.data;
            },
            showSignLandContractOfflineDialog(landContract) {
                this.landContract = JSON.parse(JSON.stringify(landContract));
                this.reason = null;
                this.signLandContractOfflineDialogVisible = true;
            },
            async signLandContractOffline() {
                if (!confirm('确定要签约吗？')) {
                    return;
                }

                await this.$axios.post('/api/LandContract/SignLandContractToOffline', {
                    id: this.landContract.id,
                    reason: this.reason,
                    signOfflineContractAttachments: this.landContract.signOfflineContractAttachments,
                });
                this.signLandContractOfflineDialogVisible = false;
                this.loadLandContracts();
            },
            async syncLandContractOnline(id) {
                await this.$axios.post('/api/LandContract/SyncLandContractOnline', { id: id });
                this.$message.success('同步完成。');
                this.loadLandContracts();
            },
        },
        created() {
            this.loadLandContracts();
        },
    };
</script>